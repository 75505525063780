/**
 *  Home.vue
**/
/* template import */
<template src="./home.html"></template>
/* style import */
<style lang="scss" src="./home.scss"></style>
<script>
import { mapMutations, mapState } from 'vuex'
import { MODULE_NAME, MUTATIONS } from '@/store/map'
import { GeoJsonLayer } from '@deck.gl/layers'
import { MapboxLayer } from '@deck.gl/mapbox';
import { CartoLayer, MAP_TYPES, FORMATS, getData, setDefaultCredentials, colorCategories, colorContinuous } from '@deck.gl/carto'
import ViewTemplate from '@/components/view-template/ViewTemplate.vue';
import layerManager from '@/components/map-component/map-utils/layerManager'
import { viewportFeaturesFunctions } from '@/utils/viewportFeatures'

export default {
  name: 'app-home',
  components: {
    ViewTemplate
  },
  data: () => ({
    storesData: []
  }),
  async mounted () {

    console.clear();
    console.log('state', this.state);

    // Only needed for Carto
    setDefaultCredentials(this.credentials);
    console.log('creds', this.credentials);

    let colors = {
      'dark_blue'   : '#0174b7',
      'light_blue'  : '#1cb7ed',
      'green'       : '#02a16f',
      'orange'      : '#e65400',
      'yellow'      : '#f2e300'
    };

    let status_to_color = {
      'Active'             : 'green',
      'Under Development'  : 'light_blue', 
      'Speculative'        : 'yellow',
      'Discontinued'       : 'orange'
    };

    let site_to_color = {
      'Greenfield'           : 'green',
      'Brownfield'           : 'yellow', 
      'Former Military Base' : 'orange',
      'Satellite City'       : 'light_blue',
      'Greyfield'            : 'dark_blue',
    };

    let entity_to_color = {
      'Private'                    : 'green',
      'Public'                     : 'orange', 
      'Public-Private Partnership' : 'yellow'
    };

    let colorMap = {
        1: [250,250,110,255],
        2: [196,236,116,255],
        3: [146,220,126,255],
        4: [100,201,135,255],
        5: [8,159,143,255],
    };

    let sizeMap = {
        1: 50,
        2: 40,
        3: 30,
        4: 20,
        5: 10,
    };



    function getColor(data){
      let color_key = data['properties']['planned_population_scale'];
      // console.log(data);
      // console.log(color_key);
      if (color_key in colorMap){
        return colorMap[color_key];
      } else{
        return [0,0,0,255]
      }
    }

    function getSize(data){
      let size_key = data['properties']['planned_population_scale'];
      console.log(data);
      console.log(size_key);
      if (size_key in sizeMap){
        return sizeMap[size_key];
      } else{
        return 5;
      }
    }

    function randomByte(){
      return Math.floor(Math.random() * 256);
    }

    function randomSize(){
      return (Math.floor(Math.random() * 4) + 1) * 5;
    }

    ///////////////////

    layerManager.addLayer(
      new GeoJsonLayer({
        id: 'MPCLayer',
//        data: 'https://special-sensitive-data.s3.us-west-2.amazonaws.com/sample_mpc_data.geojson',
        data: 'https://special-sensitive-data.s3.us-west-2.amazonaws.com/abridged_data_sample_filtered.geojson',
        pointRadiusMinPixels: 5,
        getFillColor: d => getColor(d),
//        getFillColor: d => [randomByte(),randomByte(), randomByte(), 255],
        getPointRadius: d => getSize(d), 
        highlightColor: [0, 0, 128, 128],
        pickable: true,
        visible: true,
      })
    )

    // Here are some remotely hosted GeoJSON data sets

    // https://raw.githubusercontent.com/andyagtech/map_datasample/master/airports.geojson
    // https://special-sensitive-data.s3.us-west-2.amazonaws.com/airports_indented.geojson
    // Test the changes by these functions
    //    getRadius: d => Math.sqrt(d.exits)
    //    getFillColor: d => [255, 140, 0],
    //      


    layerManager.addLayer(
      new GeoJsonLayer({
        id: 'SeaplaneLayer',
        data: 'https://raw.githubusercontent.com/andyagtech/map_datasample/master/seaplane_bases.geojson',
        pointRadiusMinPixels: 2,
        getFillColor: [0,255,0,255],
        
        /* props inherited from Layer class */      
        // autoHighlight: false,
        // coordinateOrigin: [0, 0, 0],
        // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
        highlightColor: [0, 0, 128, 128],
        pickable: true,
        visible: false,
      })
    )

    layerManager.addLayer(
      new GeoJsonLayer({
        id: 'HeliportsLayer',
        data: 'https://raw.githubusercontent.com/andyagtech/map_datasample/master/heliports.geojson',
        pointRadiusMinPixels: 2,
        getFillColor: [255,0,255,255],
        
        /* props inherited from Layer class */      
        // autoHighlight: false,
        // coordinateOrigin: [0, 0, 0],
        // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
        highlightColor: [0, 0, 128, 128],
        pickable: true,
        visible: false,
      })
    )

    const port_layer =  new GeoJsonLayer({
        id: 'PortsLayer',
        data: 'https://raw.githubusercontent.com/andyagtech/map_datasample/master/ports.geojson',
        pointRadiusMinPixels: 2,
        getFillColor: [255,0,0,255],
        getFilterValue: d => d.properties.id,
        filterRange: [0, 3000],        
        /* props inherited from Layer class */      
        // autoHighlight: false,
        // coordinateOrigin: [0, 0, 0],
        // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
        highlightColor: [0, 0, 128, 128],
        pickable: true,
        visible: false,
      });

    layerManager.addLayer(
      port_layer
    );
    console.log(port_layer);


    layerManager.addLayer(
      new GeoJsonLayer({
        id: 'AirportsLayer',
        data: 'https://raw.githubusercontent.com/andyagtech/map_datasample/master/airports.geojson',
        pointRadiusMinPixels: 2,
        getFillColor: [0,255,255,255],
        
        /* props inherited from Layer class */      
        // autoHighlight: false,
        // coordinateOrigin: [0, 0, 0],
        // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
        highlightColor: [0, 0, 128, 128],
        pickable: true,
        visible: false,
      })
    )




    //////////////////


    // layerManager.addLayer(
    //   new CartoLayer({
    //     id: 'railroads',
    //     connection: 'carto_dw',
    //     type: MAP_TYPES.QUERY,
    //     data: 'SELECT geom, scalerank FROM cartobq.public_account.ne_10m_railroads_public',
    //     pickable: true,
    //     lineWidthScale: 20,
    //     lineWidthMinPixels: 2,
    //     autoHighlight: true,
    //     highlightColor: [0, 255, 0],
    //     pointRadiusMinPixels: 2.5,
    //     getLineColor: colorContinuous({
    //       attr: 'scalerank',
    //       domain: [4, 5, 6, 7, 8, 9, 10],
    //       colors: 'BluYl'
    //     })
    //   })
    // )

    // The layers I want to see

    //SELECT  ST_Transform(the_geom_webmercator,5070) AS the_geom_webmercator, the_geom, cartodb_id, type

//     layerManager.addLayer(
//       new CartoLayer({
//         id: 'airports',
//         connection: 'carto_dw',
//         type: MAP_TYPES.QUERY,
// //        data: 'SELECT ST_Transform(the_geom_webmercator,5070) AS the_geom_webmercator, the_geom, cartodb_id, type FROM carto-dw-ac-had6rhlh.shared.osm_world_airports_projected',
//         data: 'SELECT * FROM carto-dw-ac-had6rhlh.shared.osm_world_airports_projected',
//         pickable: true,
//         autoHighlight: true,
//         highlightColor: [0, 255, 0],
//         pointRadiusMinPixels: 5
//       })
//     )

    
    // layerManager.addLayer(
    //   new CartoLayer({
    //     id: 'airports_2',
    //     connection: 'carto_dw',
    //     type: MAP_TYPES.QUERY,
    //     data: 'SELECT * FROM carto-dw-ac-had6rhlh.shared.osm_world_airports_projected',
    //     geo_column: 'the_geom_equal',
    //     pickable: true,
    //     autoHighlight: true,
    //     highlightColor: [255, 0, 0],
    //     pointRadiusMinPixels: 5
    //   })
    // )

  /////////

    // layerManager.addLayer(
    //   new CartoLayer({
    //     id: 'masterplanned_cities',
    //     connection: 'carto_dw',
    //     type: MAP_TYPES.QUERY,
    //     data: 'SELECT * FROM carto-dw-ac-had6rhlh.shared.mpcdata_latam_abridged_formatted',
    //     pickable: true,
    //     autoHighlight: true,
    //     getFillColor: [255,192,203,255],
    //     highlightColor: [0, 255, 0],
    //     pointRadiusMinPixels: 5
    //   })
    // )

  //////////////




    // layerManager.addLayer(
    //   new CartoLayer({
    //     id: 'eezs',
    //     connection: 'carto_dw',
    //     type: MAP_TYPES.QUERY,
    //     data: 'SELECT * FROM carto-dw-ac-had6rhlh.shared.eezs',
    //     pickable: true,
    //     lineWidthScale: 20,
    //     lineWidthMinPixels: 2,
    //     autoHighlight: true,
    //     highlightColor: [0, 255, 0],
    //   })
    // )


  /////////////////////////

    // const geojsonData = await getData({
    //   type: MAP_TYPES.TABLE,
    //   source: `public_account.retail_stores`,
    //   connection: 'carto_dw',
    //   format: FORMATS.GEOJSON
    // })
    
    // this.storesData = geojsonData.features

    // layerManager.addLayer(
    //   new GeoJsonLayer({
    //     id: 'stores',
    //     data: geojsonData.features,
    //     pointRadiusUnits: 'pixels',
    //     lineWidthUnits: 'pixels',
    //     pickable: true,
    //     getPointRadius: 3,
    //     autoHighlight: true,
    //     highlightColor: [0, 255, 0],
    //     getFillColor: colorCategories({
    //       attr: 'storetype',
    //       domain: ['Supermarket', 'Discount Store', 'Hypermarket', 'Drugstore', 'Department Store'],
    //       colors: 'Pastel'
    //     }),
    //   })
    // )

    // layerManager.addLayer(
    //   new CartoLayer({
    //     id: 'buildings',
    //     connection: 'bqconn',
    //     type: MAP_TYPES.TILESET,
    //     data: 'cartobq.public_account.msft_buildings',
    //     visible: false,
    //     pointRadiusUnits: 'pixels',
    //     getFillColor: [240, 142, 240]
    //   })
    // )

    this[MUTATIONS.SET_MAP_LOADED](true)
  },
  methods: {
    ...mapMutations(MODULE_NAME, [
      MUTATIONS.SET_VIEWPORT_FEATURES,
      MUTATIONS.SET_MAP_LOADED
    ])
  },
  computed: {
    ...mapState(MODULE_NAME, ['viewState', 'credentials'])
  },
  watch: {
    viewState(v) {
      const viewportFeatures = viewportFeaturesFunctions.compute(v, this.storesData)
      this[MUTATIONS.SET_VIEWPORT_FEATURES](viewportFeatures)
    },
    storesData(d) {
      this[MUTATIONS.SET_VIEWPORT_FEATURES](d)
    }
  }
}
</script>
